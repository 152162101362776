<template>
  <div class="company-contain">
    <div class="head_box">
      <div class="filter-box">
        <!-- 日周月榜 -->
        <div class="timeBox" style="padding-left: 20px">
          <el-radio-group
            class="radio-group"
            v-model="radio"
            size="small"
            @change="getListByTime"
          >
            <el-radio-button label="1">日榜</el-radio-button>
            <el-radio-button label="2">周榜</el-radio-button>
            <el-radio-button label="3">月榜</el-radio-button>
          </el-radio-group>
        </div>
        <!-- 时间搜索 -->
        <div class="dataBox" style="margin-left: 20px">
          <el-date-picker
            v-if="this.radio == 1"
            v-model="datetime"
            type="date"
            :picker-options="pickerOptions"
            placeholder="选择日期"
            value-format="yyyyMMdd"
            @change="chooseDate"
          >
          </el-date-picker>
          <el-date-picker
            ref="menuWeek"
            id="getWeek"
            v-if="this.radio == 2"
            v-model="datetime"
            type="week"
            :picker-options="pickerDisabled"
            format="yyyy 第 WW 周"
            placeholder="选择日期"
            value-format="yyyyMMdd"
            @change="selectWeekDate"
          >
          </el-date-picker>

          <el-date-picker
            v-if="this.radio == 3"
            v-model="datetime"
            type="month"
            :picker-options="monthPickerOptions"
            placeholder="选择月"
            value-format="yyyyMM"
            @change="selectMonthDate"
          >
          </el-date-picker>
        </div>
        <!-- 文本框搜索 -->
        <div class="searchContent" style="margin-left: 20px">
          <el-input
            class="searchInput"
            placeholder="请输入您要搜索的内容"
            prefix-icon="el-icon-search"
            v-model="nickname"
          >
          </el-input>
        </div>
        <!-- 教学指导 -->
        <div class="teachingGuidance" style="position: absolute; right: 2%">
          <el-popover
            class="tightCount"
            placement="bottom"
            width="300"
            trigger="click"
          >
            <div class="rightBox">
              <div
                class="head_title"
                style="text-align: center; font-size: 16px; line-height: 40px"
              >
                帮助中心
              </div>
              <div class="listBox">
                <div
                  class="box1"
                  style="display: flex; margin-top: 10px; position: relative"
                >
                  <div
                    class="num"
                    style="
                      width: 14px;
                      height: 14px;
                      background: #00f;
                      border-radius: 7px;
                      color: #fff;
                      text-align: center;
                      line-height: 14px;
                      font-size: 12px;
                      position: absolute;
                      top: 2px;
                      left: 0;
                    "
                  >
                    1
                  </div>
                  <div class="title" style="margin-left: 20px; font-size: 14px">
                    复盘网易、雪球等测试小游戏刷屏的背后？
                  </div>
                </div>
                <div
                  class="box1"
                  style="display: flex; margin-top: 10px; position: relative"
                >
                  <div
                    class="num"
                    style="
                      width: 14px;
                      height: 14px;
                      background: #00f;
                      border-radius: 7px;
                      color: #fff;
                      text-align: center;
                      line-height: 14px;
                      font-size: 12px;
                      position: absolute;
                      top: 2px;
                      left: 0;
                    "
                  >
                    2
                  </div>
                  <div class="title" style="margin-left: 20px; font-size: 14px">
                    线下课程 | 想要成为一个优秀的运营负责人，
                    到底该怎么高效做规划、带团队？线下课程 |
                    想要成为一个优秀的运营负责人，
                    到底该怎么高效做规划、带团队？
                  </div>
                </div>
                <div
                  class="box1"
                  style="display: flex; margin-top: 10px; position: relative"
                >
                  <div
                    class="num"
                    style="
                      width: 14px;
                      height: 14px;
                      background: #00f;
                      border-radius: 7px;
                      color: #fff;
                      text-align: center;
                      line-height: 14px;
                      font-size: 12px;
                      position: absolute;
                      top: 2px;
                      left: 0;
                    "
                  >
                    3
                  </div>
                  <div class="title" style="margin-left: 20px; font-size: 14px">
                    超详细的搜索攻略：彻底讲透搜索技巧超详细的搜索攻略：彻底讲透搜索技巧超详细的搜索攻略：彻底讲透搜索技巧超详细的搜索攻略：超详细的搜索攻略：彻底讲透搜索技巧超详细的搜索攻略：彻底讲透搜索技巧彻底讲透搜索技巧超详细的搜索攻略：彻底讲透搜索技巧
                  </div>
                </div>
                <div
                  class="box1"
                  style="display: flex; margin-top: 10px; position: relative"
                >
                  <div
                    class="num"
                    style="
                      width: 14px;
                      height: 14px;
                      background: #00f;
                      border-radius: 7px;
                      color: #fff;
                      text-align: center;
                      line-height: 14px;
                      font-size: 12px;
                      position: absolute;
                      top: 2px;
                      left: 0;
                    "
                  >
                    4
                  </div>
                  <div class="title" style="margin-left: 20px; font-size: 14px">
                    线下课程 | 想要成为一个优秀的运营负责人，
                    到底该怎么高效做规划、带团队？ 拷贝线下课程 |
                    想要成为一个优秀的运营负责人，
                    到底该怎么高效做规划、带团队？ 拷贝线下课程 |
                    想要成为一个优秀的运营负责人，
                    到底该怎么高效做规划、带团队？ 拷贝
                  </div>
                </div>
              </div>
            </div>
            <el-button slot="reference" type="primary">教学指导</el-button>
          </el-popover>
        </div>
      </div>
      <!-- 下划线 -->
      <div class="divide">
        <el-divider></el-divider>
      </div>
      <!--筛选条件  分类-->
      <div class="filter-box">
        <div class="box1">
          <span>任务分类：</span>
          <div class="filter-box1">
            <span @click="allClas" :class="{ activeClass: isAll == 0 }"
              >全部</span
            >
            <span
              @click="isAllS(item)"
              :class="{ activeClass: isAll == item.id }"
              v-for="item in typeLsit"
              :key="item.id"
              >{{ item.name }}</span
            >
          </div>
        </div>
      </div>
      <!-- 高级筛选 -->
      <div class="filter-box">
        <div class="box1">
          <span>高级筛选：</span>
          <div class="filter-box1">
            <!-- 粉丝数筛选 -->
            <el-select
              v-model="fansValue"
              placeholder="请选择"
            >
              <el-option
                v-for="item in fansOptions"
                :key="`fansindex_${item.fansValue}`"
                :label="item.fansLabel"
                :value="item.fansValue"
              >
              </el-option>
            </el-select>
            <!-- 带货口碑筛选 -->
            <el-select
              v-model="pointsValue"
              placeholder="请选择"
              style="margin-left: 25px"
            >
              <el-option
                v-for="item in pointsOptions"
                :key="`pointsindex_${item.pointsValue}`"
                :label="item.pointsLabel"
                :value="item.pointsValue"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <!-- 下划线 -->
    <div style="background-color: #f3f3f6; height: 20px; width: 100%"></div>
    <!-- 表单 -->
    <div class="table-box">
      <el-table
        :data="taskList"
        class="college-table"
        style="width: 100%; flex: 1"
        size="medium"
        :header-cell-style="{ fontWeight: 'normal', color: '#333' }"
        :cell-style="{ fontSize: '12px', color: '#333' }"
      >
        <el-table-column
          prop="rank"
          label="排行"
          align="left"
          width="100"
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.rank }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="nickname"
          width="400"
          label="播主"
          align="left "
          class-name="student-name"
        >
          <template slot-scope="scope">
            <div class="showing">
              <img class="imgBox" :src="scope.row.avatar_medium_url" />
              <span class="showing-name">{{ scope.row.nickname }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="follower_goup_count"
          width="100"
          :label="label"
          align="center"
          class-name="student-name"
        >
          <template slot="header" slot-scope="scope">
            <span style="color: #ff0000">{{ label }}</span>
          </template>
          <template slot-scope="scope">
            <div
              style="
                height: 32px;
                background: #ffa1a8;
                border: 1px solid rgba(255, 161, 168, 1);
                border-radius: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <span class="student-name-title" style="color: #ff0000"
                >+{{ scope.row.follower_goup_count }}</span
              >
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="follower_count"
          label="粉丝总数"
          align="center"
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{
              scope.row.follower_count
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="total_favorited"
          label="点赞总数"
          align="center"
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{
              scope.row.total_favorited
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="aweme_count"
          label="视频总数"
          align="center"
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{
              scope.row.history_aweme_count
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250" align="center">
          <template slot-scope="scope">
            <div class="operation" @click="changeToDetail(scope.row)">
              <el-button type="primary" size="small">查看详情</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页按钮 -->
    <el-pagination
      class="pages-center"
      :current-page.sync="adminPages.currentPageNum"
      :page-size="adminPages.eachPageNum"
      layout="prev, pager, next, jumper"
      :total="adminPages.total"
      @current-change="adminCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
import _ from "lodash";
import { shwoRanking, shwoCategory } from "@/utils/apis";

export default {
  name: "HotVideo",
  data() {
    let _minTime = null;
    let _maxTime = null;
    return {
      pickerDisabled: {
        //禁选当天之后的日期
        disabledDate: (time) => {
          return time.getTime() > new Date() * 1 + 600 * 1000;
        },
      },
      pickerOptions: {
        onPick(time) {
          if (!time.maxDate) {
            let timeRange = 7 * 24 * 3600 * 1000;
            _minTime = time.minDate.getTime(); // 最小时间
            _maxTime = time.minDate.getTime() + timeRange; // 最大时间
          } else {
            _minTime = _maxTime = null;
          }
        },
        disabledDate(time) {
          let afterToday = Date.now() - 3600 * 1000 * 24;
          if (_maxTime) {
            _maxTime = _maxTime <= afterToday ? _maxTime : afterToday;
          } else {
            return time.getTime() > Date.now() - 3600 * 1000 * 24;
          }
          if (_minTime && _maxTime) {
            return time.getTime() < _minTime || time.getTime() > _maxTime;
          }
        },
      },
      monthPickerOptions: {
        //月榜时间选择器的约束
        disabledDate(time) {
          return time.getMonth() + 1 != new Date().getMonth() + 1;
        },
      },
      isAll: 0,
      radio: 1,
      type: 1, //类型日周月
      datetime: "", //获取选择的日周月数据
      nickname: "", //搜索框搜索内容
      cate: "", //分类名称
      typeLsit: [], //分类列表
      task_condition: "",
      taskList: [], //任务列表
      //分页
      adminPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      label: "单日涨粉",
      fansOptions: [
        {
          fansValue: "",
          fansLabel: "不限",
        },
        {
          fansValue: "1",
          fansLabel: "粉丝数：<1万",
        },
        {
          fansValue: "2",
          fansLabel: "粉丝数：1万~10万",
        },
        {
          fansValue: "3",
          fansLabel: "粉丝数：10万~50万",
        },
        {
          fansValue: "4",
          fansLabel: "粉丝数：50万~100万",
        },
        {
          fansValue: "5",
          fansLabel: "粉丝数：100万~500万",
        },
        {
          fansValue: "6",
          fansLabel: "粉丝数：500万~1千万",
        },
      ],
      pointsOptions: [
        { pointsValue: "", pointsLabel: "带货口碑：不限" },
        { pointsValue: "1", pointsLabel: "带货口碑：高（>4.7）" },
        { pointsValue: "2", pointsLabel: "带货口碑：中（4.2~4.7）" },
        { pointsValue: "3", pointsLabel: "带货口碑：低（<4.2）" },
      ],
      fansValue: "", //粉丝数筛选
      pointsValue: "", //带货口碑筛选
    };
  },
  mounted() {
    this.chooseDate();
    this.getTaskType();
  },
  methods: {
    isAllS(item) {
      this.isAll = item.id;
      this.cate = item.name;
    },
    //默认选中“全部”分类
    allClas() {
      this.isAll = 0;
      this.cate = "";
    },

    // 显示任务分类
    getTaskType() {
      let params = {};
      shwoCategory(params).then((res) => {
        this.typeLsit = res.data.list;
      });
    },

    getListByTime() {
      this.datetime = ""; //清空之前选中的时间数据
      if (this.radio == 1) {
        this.type = 1;
      }
      if (this.radio == 2) {
        this.type = 2;
      }
      if (this.radio == 3) {
        this.type = 3;
      }
      this.label =
        this.radio == 1
          ? "单日涨粉"
          : this.radio == 2
          ? "单周涨粉"
          : this.radio == 3
          ? "单月涨粉"
          : "单日涨粉";
      this.getAllClass();
    },
    // 选择日
    chooseDate(val) {
      this.datetime = val;
    },
    // 选择周
    selectWeekDate(val) {
      this.datetime = val;
    },
    //选择月份
    selectMonthDate(val) {
      this.datetime = val;
    },
    // 时间戳为13位
    dateFormatTwo: function (time) {
      var date = new Date(time);
      var year = date.getFullYear();
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },

    // 获取所有涨粉榜数据
    getAllClass() {
      let param = {
        category: this.cate, //播主分类
        nickname: this.nickname, //搜索框昵称
        fan_type: this.fansValue, //根据粉丝数筛选
        points_type: this.pointsValue, //根据带货口碑筛选
        type: this.type, //榜单类型，默认日榜
        page: this.adminPages.currentPageNum,
        limit: this.adminPages.eachPageNum, //页面显示条数
        date: this.datetime,
      };
      if (this.cate == "") {
        delete param.category;
      }
      if (this.nickname == "") {
        delete param.nickname;
      }
      if (this.fansValue == "") {
        delete param.fan_type;
      }
      if (this.datetime == "") {
        delete param.date;
      }
      if (this.pointsValue == "") {
        delete param.points_type;
      }
      shwoRanking(param)
        .then((res) => {
          this.taskList = res.data.list;
          this.adminPages.total = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //分页
    adminCurrentChange(val) {
      this.adminPages.currentPageNum = val;
      this.getAllClass();
    },
    //跳到详情页
    changeToDetail(row) {
      this.$router.push({
        path: "/student/hot/authorDetail",
        query: {
          author_id: row.userid,
        },
      });
    },

    //防抖
    sendAjax: _.debounce(function () {
      this.getAllClass();
    }, 1000),
  },
  watch: {
    isAll(d) {
      this.getAllClass();
    },
    nickname(d) {
      this.sendAjax();
    },
    datetime(d) {
      this.sendAjax();
    },
    fansValue(d) {
      this.sendAjax();
    },
    pointsValue(d) {
      this.sendAjax();
    },
  },
};
</script>

<style scoped lang="scss">
.activeClass {
  background: #2338e6;
  color: #ffffff;
}

.company-contain {
  margin-bottom: 20px;
  background-color: #ffffff;

  .timeBox {
    .radio-group {
      ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #2338e6;
        border-color: #2338e6;
      }

      ::v-deep
        .el-radio-button__orig-radio:checked
        + .el-radio-button__inner:hover {
        color: #fff;
      }

      ::v-deep .el-radio-button__inner:hover {
        color: #2338e6;
      }
    }
  }

  .teachingGuidance {
    ::v-deep .el-button--primary {
      color: #fff;
      background-color: #495df1;
      border-color: #495df1;
    }

    ::v-deep .el-button--primary:focus,
    .el-button--primary:hover {
      background: #495df1;
      border-color: #495df1;
      color: #fff;
    }

    ::v-deep .el-button {
      padding: 8px 13px;
      border-radius: 2px;
    }
  }

  .operation {
    ::v-deep .el-button--primary {
      color: #fff;
      background-color: #495df1;
      border-color: #495df1;
    }

    ::v-deep .el-button--primary:focus,
    .el-button--primary:hover {
      background: #495df1;
      border-color: #495df1;
      color: #fff;
    }

    ::v-deep .el-button {
      padding: 8px 13px;
      border-radius: 2px;
    }
  }

  .divide {
    padding: 0 20px;
  }

  .filter-box {
    display: flex;
    position: relative;

    .box1 {
      padding: 10px 20px;
      display: flex;
      line-height: 40px;

      .filter-box1 {
        flex: 1;

        span {
          margin-top: 6px;
          line-height: 32px;
          display: inline-block;
          width: 66px;
          height: 32px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  ::v-deep .el-table td {
    border-bottom: 8px solid #ebeef5;
  }

  ::v-deep .el-table {
    display: flex;
    flex-direction: column;
    height: calc(100% - 114px);
  }

  ::v-deep .el-table--border::after,
  ::v-deep .el-table--group::after,
  ::v-deep .el-table::before {
    background-color: transparent;
  }

  ::v-deep .el-table--medium td,
  ::v-deep .el-table--medium th {
    padding: 10px 9px;
  }

  ::v-deep .el-table__footer-wrapper,
  ::v-deep .el-table__header-wrapper {
    overflow: initial;
  }

  ::v-deep .el-table__body-wrapper {
    overflow: auto;
  }

  .showing {
    display: flex;
    align-items: center;
    /*width: 30px;*/
    height: 30px;

    img {
      display: inline-block;
      height: 30px;
      width: 30px;
      margin-right: 10px;
      border-radius: 50%;
    }

    .showing-name {
      max-width: 260px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
